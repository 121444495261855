import { createSlice } from "@reduxjs/toolkit";
// import { getLocalStorageItem } from "../Utils";

const initialState = {
  openLoginModal: false,
  openSignUpModal: false,
  openOtpModal: false,
  showAddressModal:false
};

export const commonslice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setOpenLoginModal: (state, action) => {
      state.openLoginModal = action.payload;
    },
    setOpenSignUpModal: (state, action) => {
      state.openSignUpModal = action.payload;
    },
    setOpenOtpModal: (state, action) => {
      state.openOtpModal = action.payload;
    },
    setOpenAddressModal: (state, action) => {
      state.showAddressModal = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setOpenLoginModal,setOpenAddressModal, setOpenSignUpModal, setOpenOtpModal } =
  commonslice.actions;

export default commonslice.reducer;
