import React, { useEffect, useState } from "react";
import {  useLocation, useNavigate } from "react-router-dom";
import { Images } from "../../ImageController/Index";
import {
  createImageUrl,
  handleAddToFavourite,
  handleRemoveFromFavourite,
} from "../../Utils";
import { GetDataBySectionId } from "../../ApiServices/Apifun";
import { useDispatch, useSelector } from "react-redux";
import QuickOverView from "../Home/QuickOverView";
import { setOpenLoginModal } from "../../Redux/commonSlice";
import { Spin } from "antd";
import CommonCard from "../../CommmonComponents/CommonCard";

const Collection = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [spin, setSpin] = useState(false);
  const [openQuicjView, setOpenQuickView] = useState(false);
  const [slug, setSlug] = useState(null);
  const isLoggedIn = useSelector((state) => state.user.userDetail);
  const [data, setData] = useState([]);

  const getalldata = () => {
    setSpin(true);
    GetDataBySectionId(`${location?.state?.data || 1}`)
      .then((res) => {
        if (res.status) {
          setData(res.data.sectionData);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setSpin(false));
  };
  useEffect(() => {
    if (!location.state) {
      navigate(-1);
    } else {
      getalldata();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Spin spinning={spin}>
        <div className="md:container md:mx-auto mx-4 select-none">
          {Object.keys(data).map((key, index) => {
            return (
              <div key={index}>
                <div className="md:container md:mx-auto mx-4  select-none">
                  <div className="flex  justify-between m-0 items-center capitalize">
                    <div className=" w-full m-14 flex justify-center items-center">
                      <p className="text-textColor sectionHeading relative text-2xl md:text-5xl font-thin font-heading">
                        {key}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="md:container md:mx-auto mx-4  select-none">
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-5  place-items-center">
                    {data[key].map((i, index) => {
                      const image =
                        typeof i.default_image == "string"
                          ? JSON.parse(i.default_image)
                          : i.default_image;

                      return (
                        <CommonCard index={index} image={image.length !== 0
                          ? createImageUrl(image[0])
                          : Images.NoImage} handleFav={()=>isLoggedIn
                            ? !i.is_favourite
                              ? handleAddToFavourite(
                                  i.product_id,
                                  getalldata
                                )
                              : handleRemoveFromFavourite(
                                  i.product_id,
                                  getalldata
                                )
                            : dispatch(setOpenLoginModal(true))} data={i} setslug={setSlug} setopen={setOpenQuickView}/>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Spin>
      {openQuicjView && (
        <QuickOverView
          open={openQuicjView}
          setopen={setOpenQuickView}
          productId={slug}
        />
      )}
    </>
  );
};

export default Collection;
