import React, { useEffect, useState } from "react";
import { Images } from "../../ImageController/Index";
// import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { GetAllData } from "../../ApiServices/Apifun";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Spin } from "antd";
import {
  createImageUrl,
  handleAddToFavourite,
  handleRemoveFromFavourite,
} from "../../Utils";
import { useDispatch, useSelector } from "react-redux";
import QuickOverView from "./QuickOverView";
import { setOpenLoginModal } from "../../Redux/commonSlice";
import CommonCard from "../../CommmonComponents/CommonCard";
import Slider from "react-slick";

const ProductSuggestions = ({ banner }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [allData, setAllData] = useState([]);
  const [openQuick, setOpenQuick] = useState(false);
  const [productId, setProductId] = useState(null);
  const isLoggedIn = useSelector((state) => state.user.userDetail);
  const [loading, setLoading] = useState(false);
  const getalldata = () => {
    setLoading(true);
    GetAllData()
      .then((res) => {
        if (res.status) {
          setAllData(res.data.sectionData);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    getalldata();
  }, []);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true, // Corrected here
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };
  return (
    <>
      <Spin spinning={loading}>
        {/* <div className="grid grid-cols-3 h-1/2 w-full container mx-auto place-items-center gap-x-8">
            <div className="col-span-2 w-full h-full">
              <img src={Images.washingmachine} alt="product-image" className="w-full h-full " />
            </div>
            <div className="h-full w-full flex-col justify-center items-center">
              <img src={Images.pc} alt="product-image" className="w-full mb-8" />
              <img src={Images.perfume} alt="product-image" className="w-full" />
            </div>
       
        </div> */}
        {Object.keys(allData).map((key, index) => {
          return (
            <div key={index}>
              {banner.filter((i) => i.section_id === allData[key][0].section_id)
                .length !== 0 && (
                <div className="mt-4 w-9/12 max-h-[200px] overflow-hidden m-auto shadow-sm shadow-blue-200">
                  <Slider {...settings}>
                    {banner && banner.length !== 0 ? (
                      banner
                        .filter(
                          (i) => i.section_id === allData[key][0].section_id
                        )
                        .map((i) => {
                          return (
                            <div>
                              <LazyLoadImage
                                src={createImageUrl(i.image_name, "banner")}
                                crossOrigin="anonymous"
                                alt="adBanner"
                                className=" object-fill  "
                              />
                            </div>
                          );
                        })
                    ) : (
                      <div>
                        <LazyLoadImage
                          loading="lazy"
                          src={Images.BannerImage}
                          className="w-full  "
                        />
                      </div>
                    )}
                  </Slider>
                </div>
              )}
              <div className="md:container md:mx-auto mx-4 mt-4 select-none">
                {/* <div className="flex  justify-between mt-4 items-center capitalize">
                  <div className=" w-full flex justify-center items-center">
                    <p className="text-textColor  relative text-lg md:text-5xl font-thin font-heading italic opacity-25">
                      Shop Now
                    </p>
                  </div>
                </div> */}
                <div className="flex  justify-between m-0 items-center capitalize">
                  <div className=" w-full mx-14 my-6 flex justify-start lg:justify-center items-center">
                    <p className="text-textColor sectionHeading relative text-2xl md:text-4xl font-thin font-heading">
                      {key}
                    </p>
                  </div>
                  <div className="w-full absolute right-0 flex justify-end items-center">
                    <p
                      onClick={() =>
                        navigate("/collection", {
                          state: { data: allData[key][0]?.section_id },
                        })
                      }
                      className="bg-white hover:bg-textColor text-black hover:shadow-[inset_0_0_5px_-1px_#ffffff] hover:shadow-white hover:text-white border border-gray-400 hover:border-none cursor-pointer p-2 rounded-sm text-xs transition-all "
                    >
                      View All
                    </p>
                  </div>
                </div>
              </div>
              <div className="md:container md:mx-auto mx-4  select-none">
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-5  place-items-center">
                  {allData[key].slice(0, 5).map((i, index) => {
                    const image =
                      typeof i.default_image == "string"
                        ? JSON.parse(i.default_image)
                        : i.default_image;

                    return (
                      <CommonCard
                        index={index}
                        image={
                          image.length !== 0
                            ? createImageUrl(image[0])
                            : Images.NoImage
                        }
                        handleFav={() =>
                          isLoggedIn
                            ? !i.is_favourite
                              ? handleAddToFavourite(i.product_id, getalldata)
                              : handleRemoveFromFavourite(
                                  i.product_id,
                                  getalldata
                                )
                            : dispatch(setOpenLoginModal(true))
                        }
                        data={i}
                        setslug={setProductId}
                        setopen={setOpenQuick}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </Spin>
      {openQuick && (
        <QuickOverView
          open={openQuick}
          setopen={setOpenQuick}
          productId={productId}
        />
      )}
    </>
  );
};

export default ProductSuggestions;
