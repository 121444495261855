import React from "react";
import {  MdOutlinePerson } from "react-icons/md";
import { BsCart4 } from "react-icons/bs";
import { FaRegAddressCard } from "react-icons/fa";
import { IoLockClosed } from "react-icons/io5";


const ProfileTabbar = ({ activeTab, setActiveTab }) => {
  return (
    <>
      <div className="bg-white rounded-md p-4 ">
        <div className="flex flex-wrap justify-start items-center text-[10px] xs:text-[13px] md:text-[14px] gap-0 md:gap-4 ">
          <div>
            <div
              onClick={() => setActiveTab(1)}
              className="flex justify-center gap-2 items-center p-2"
            >
              <div>
                <MdOutlinePerson className="text-black" size={20} />
              </div>
              <p className="cursor-pointer">Personal Info</p>
            </div>
            {activeTab === 1 ? (
              <div className="border-t-2 p-2 animate-slide-in border-primary"></div>
            ) : (
              <div className="p-2"></div>
            )}
          </div>
          <div>
            <div
              onClick={() => setActiveTab(2)}
              className="flex justify-center gap-2 items-center p-2"
            >
              <div>
                <FaRegAddressCard  className="text-black" size={20} />
              </div>
              <p className="cursor-pointer">Addresses</p>
            </div>
            {activeTab === 2 ? (
              <div className="border-t-2 p-2 animate-slide-in border-primary"></div>
            ) : (
              <div className="p-2"></div>
            )}
          </div>
          {/* {memberServices.includes("5") ? (
            <div>
              <div
                onClick={() => setActiveTab(3)}
                className="flex justify-center gap-2 items-center p-2"
              >
                <div>
                  <AiFillBank className="text-primary" size={20} />
                </div>
                <p className="cursor-pointer">Bank Details </p>
              </div>
              {activeTab === 3 ? (
                <div className="border-t-2 p-2 animate-slide-in border-primary"></div>
              ) : (
                <div className="p-2"></div>
              )}
            </div>
          ) : null} */}
          <div>
            <div
              onClick={() => setActiveTab(4)}
              className="flex justify-center gap-2 items-center p-2"
            >
              <div>
                <BsCart4 className="text-black" size={20} />
              </div>
              <p className="cursor-pointer">Your Orders</p>
            </div>
            {activeTab === 4 ? (
              <div className="border-t-2 p-2 animate-slide-in border-primary"></div>
            ) : (
              <div className="p-2"></div>
            )}
          </div>{" "}
          <div>
            <div
              onClick={() => setActiveTab(5)}
              className="flex justify-center gap-2 items-center p-2"
            >
              <div>
                <IoLockClosed className="text-black" size={20} />
              </div>
              <p className="cursor-pointer">Change Password </p>
            </div>
            {activeTab === 5 ? (
              <div className="border-t-2 p-2 animate-slide-in border-primary"></div>
            ) : (
              <div className="p-2"></div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileTabbar;
