import React, { useEffect, useState } from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { setOpenLoginModal } from "../Redux/commonSlice";
import { FaCartPlus, FaFacebook, FaTwitter } from "react-icons/fa6";
import { Dropdown } from "antd";
import LoginModal from "../components/LoginModal";
import SignupModal from "../components/SignupModal";
// import { MegaMenu } from "primereact/megamenu";
import { useNavigate } from "react-router-dom";
import AddressDrawer from "../CommmonComponents/AddressDrawer";
import { clearFilter, setSelectedCategory, setUserAllProfile, setUsers } from "../Redux/userSlice";
import { createImageUrl, trimString } from "../Utils";
import { GetUserDetail, getCategoryList } from "../ApiServices/Apifun";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { GiHamburgerMenu } from "react-icons/gi";

import SearchDrawer from "./SearchDrawer";
import {
  FaInstagramSquare,
  FaRegHeart,
  FaRegUser,
  FaSearch,
} from "react-icons/fa";
import SidebarDrawer from "./SidebarDrawer";
import { Images } from "../ImageController/Index";
import CartDrawer from "./CartDrawer";
import {
  //  IoChevronDown, IoChevronForward,
  IoLogoWhatsapp,
} from "react-icons/io5";
import CategoryDrawer from "./CategoryDrawer";

const DefaultLayout = ({ children }) => {
  // const [openSidebar, setOpenSidebar] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [categoryList, setCategoryList] = useState([]);
  const [sidebarCategory, setSidebarCategory] = useState([]);
  const [openSidebarDrawer, setOpenSidebarDrawer] = useState(false);
  const loginState = useSelector((state) => state.common.openLoginModal);
  const signUpstate = useSelector((state) => state.common.openSignUpModal);
  const [openSearchDrawer, setOpenSearchDrawer] = useState(false);
  const [openCartDrawer, setOpenCartDrawer] = useState(false);
  const [openCategpryDrawer, setOpenCategoryDrawer] = useState(false);
  const addressModalState = useSelector(
    (state) => state.common.showAddressModal
  );
  const isLoggedIn = useSelector((state) => state.user.userDetail);
  const userProfile = useSelector((state) => state.user.userProfile);
  const selectedCategory = useSelector((state) => state.user.selectedCategory);
  const getUserData = () => {
    GetUserDetail()
      .then((res) => {
        if (res.status) {
          dispatch(setUserAllProfile(res.data));
        }
      })
      .catch((err) => console.log(err));
  };
  function convertDataToPrimeReactMenu(data) {
    const items = [];
    data?.forEach((category) => {
      if (category.child_category.length !== 0) {
        const formattedCategory = {
          label: (
            <p
              // onClick={() =>
              //   navigate("/category", { state: { id: category.id } })
              // }
              className="uppercase underlineText relative tracking-wide py-1 px-2 text-gray-500 font-semibold text-sm cursor-pointer"
            >
              {category.name}
            </p>
          ),
          icon: null,
          items: convertChildCategories(category.child_category),
        };
        items?.push(formattedCategory);
      } else {
        const formattedCategory = {
          label: (
            <p
              onClick={() =>
                navigate("/category", { state: { id: category.id } })
              }
              className="uppercase underlineText relative tracking-wide px-2 py-1 text-gray-500 font-semibold text-sm cursor-pointer"
            >
              {category.name}
            </p>
          ),
          icon: null,
        };
        items?.push(formattedCategory);
      }
    });

    return items;
  }

  function convertChildCategories(childCategories) {
    return childCategories?.map((childCategory) => {
      const formattedChildCategory = {
        label: (
          <p
            className="cursor-pointer pt-2 underlineText relative w-auto"
            onClick={() =>
              navigate("/category", { state: { id: childCategory.id } })
            }
          >
            {childCategory.name}
          </p>
        ),
        items: convertChildCategories(childCategory.child_category),
      };
      return [formattedChildCategory];
    });
  }

  const getCategory = () => {
    getCategoryList()
      .then((res) => {
        if (res.status) {
          const items = convertDataToPrimeReactMenu(res.data);
          setSidebarCategory(res.data);
          console.log(items);
          // setCategoryList(items);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getCategory();
    //eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (isLoggedIn) {
      getUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginState, navigate]);
  const items = [
    {
      key: 1,
      disabled: isLoggedIn ? false : true,
      label: (
        <p
          onClick={() => (isLoggedIn ? navigate("/profile") : {})}
          className="font-semibold text-sm"
        >
          View Profile
        </p>
      ),
    },
    {
      key: 2,
      disabled: isLoggedIn ? false : true,
      label: (
        <p
          onClick={() => (isLoggedIn ? navigate("/orders") : {})}
          className="font-semibold text-sm"
        >
          My Orders
        </p>
      ),
    },
    {
      key: 3,
      label: (
        <p
          onClick={() =>
            isLoggedIn
              ? (localStorage.clear(),
                dispatch(setUsers(null)),
                dispatch(setUserAllProfile(null)),
                navigate("/"))
              : dispatch(setOpenLoginModal(true))
          }
        >
          {isLoggedIn ? "Logout" : "LogIn"}
        </p>
      ),
    },
  ];
  // const ssss = [
  //   { label: "Ebook", icon: null },
  //   { label: "Home & Lifestyle", icon: null },
  //   {
  //     label: "digital software",
  //     icon: null,
  //     items: [[{ label: "Digital Gaming", items: [] }]],
  //   },
  //   {
  //     label: "Beauty",
  //     icon: null,
  //     items: [
  //       [
  //         {
  //           label: "Makeup",
  //           items: [

  //               {
  //                 label: "Lipstick",
  //                 items: [[

  //                     {
  //                       label: "Streax",
  //                       items: [{ label: "Natural", items: [] }],
  //                     },

  //                 ]],
  //               },
  //             ,
  //             [{ label: "Nail Polish", items: [] }],
  //           ],
  //         },
  //       ],
  //     ],
  //   },
  //   {
  //     label: "Electronic",
  //     icon: null,
  //     items: [
  //       [{ label: "Laptops", items: [] }],
  //       [
  //         {
  //           label: "Tablets",
  //           items: [

  //               {
  //                 label: "Lenovo",
  //                 items: [
  //                   { label: "Touch Pad",items:[]}
  //                 ],
  //               },

  //             { label: "Samsung", items: [] },
  //             { label: "Apple", items: [] },
  //           ],
  //         },
  //       ],
  //       [{ label: "Mobile & Accessories", items: [] }],
  //     ],
  //   },
  //   {
  //     label: "Clothes",
  //     icon: null,
  //     items: [
  //       [
  //         {
  //           label: "Men",
  //           items: [
  //             { label: "xyz", items: [] },
  //             { label: "Cricket Bat", items: [] },
  //           ],
  //         },
  //       ],
  //       [{ label: "Women", items: [] }],
  //       [{ label: "Bags", items: [] }],
  //     ],
  //   },
  //   { label: "Sports", icon: null },
  // ];
  // const getCategoryDropdown = (category) => {
  //   const map =
  //     category?.length !== 0 &&
  //     category?.map((i, index) => {
  //       return {
  //         key: index,
  //         label:
  //           i?.child_category?.length !== 0 ? (
  //             <Dropdown
  //               overlayStyle={{ marginLeft: 100 }}
  //               overlayClassName="categoryDropDown"
  //               arrow={true}
  //               placement="bottomRight"
  //               menu={{ items: getCategoryDropdown(i.child_category) }}
  //             >
  //               <div className="flex justify-between items-center">
  //                 <p className="font-medium text-sm text-black">{i.name}</p>
  //                 <p className="font-medium text-sm text-black">
  //                   <IoChevronForward />
  //                 </p>
  //               </div>
  //             </Dropdown>
  //           ) : (
  //             <p
  //               onClick={() => navigate("/category", { state: { id: i.id } })}
  //               className="font-medium text-sm text-black"
  //             >
  //               {i.name}
  //             </p>
  //           ),
  //       };
  //     });

  //   return map;
  // };
  return (
    <>
      <div
        style={{ zIndex: 99 }}
        className="bg-[#2193d1]  fixed top-0 w-full h-28   flex justify-around items-center"
      >
        <div className="hidden md:flex w-full gap-8 justify-start ml-16 items-center">
          <FaFacebook className="cursor-pointer" color="white" size={25} />
          <IoLogoWhatsapp className="cursor-pointer" color="white" size={25} />
          <FaInstagramSquare
            className="cursor-pointer"
            color="white"
            size={25}
          />
          <FaTwitter className="cursor-pointer" color="white" size={25} />
        </div>
        <div
          onClick={() => navigate("/")}
          className=" md:hidden cursor-pointer flex justify-center items-center "
        >
          <p className="hidden md:block">&nbsp;</p>
          <LazyLoadImage
            src={Images.Logo}
            className=" flex md:hidden "
            alt="logo"
          />
        </div>
        <div
          onClick={() => navigate("/")}
          className=" w-1/2 cursor-pointer flex h-full justify-center items-center "
        >
          <LazyLoadImage
            src={Images.Logo}
            className="hidden md:block"
            alt="logo"
          />
        </div>
        <div className="md:hidden flex justify-center gap-x-4 w-full items-center">
          <div
            onClick={() => setOpenSearchDrawer(true)}
            className="flex bg-white font-futura cursor-pointer relative rounded-full w-12 flex-col justify-center items-center gap-y-1"
          >
            <div className="h-10 cursor-pointer flex justify-center items-center">
              <FaSearch color="#2193d1" size={20} />
            </div>
          </div>
          <div
            onClick={() => setOpenSidebarDrawer(true)}
            className="text-white cursor-pointer   text-4xl font-bold "
          >
            <RxHamburgerMenu />
          </div>
        </div>
        <div className="menu-items hidden w-full  font-lato md:flex gap-6 text-sm text-black justify-center items-center">
          <div
            onClick={() => setOpenSearchDrawer(true)}
            className="flex  font-futura cursor-pointer relative rounded-full w-8 flex-col justify-center items-center gap-y-1"
          >
            <div className="h-8  flex justify-center items-center">
              <FaSearch color="white" size={20} />
            </div>
            {/* <p className="cursor-pointer text-white">&nbsp;</p> */}
          </div>
          <Dropdown
            trigger={["click"]}
            arrow={false}
            placement="topCenter"
            menu={{
              items,
            }}
          >
            <div className="flex flex-col justify-center items-center gap-y-1">
              {userProfile?.user_profile?.image ? (
                <div className="w-8 cursor-pointer flex justify-center items-center  rounded-full">
                  <LazyLoadImage
                    onError={(err) => {
                      err.target.src = Images.NoImage;
                      err.target.alt = "No Image";
                    }}
                    src={createImageUrl(
                      userProfile?.user_profile?.image,
                      "userProfile"
                    )}
                    className=" rounded-full"
                    crossOrigin="anonymous"
                    alt="profile_image"
                  />
                </div>
              ) : (
                <div className=" h-8 cursor-pointer  flex justify-center items-center ">
                  <FaRegUser color="white" size={20} />
                </div>
              )}
              {/* <Tooltip>
                <p className="cursor-pointer font-futura capitalize text-white">
                  {isLoggedIn
                    ? `Hi, ${
                        userProfile?.user_profile?.user_first_name ?? ""
                      } ${userProfile?.user_profile?.user_last_name ?? ""}`
                    : ""}
                </p>
              </Tooltip> */}
            </div>
          </Dropdown>
          <div
            onClick={() => navigate("/favourites")}
            className="flex font-futura relative flex-col justify-center items-center gap-y-1"
          >
            <div className="h-8  cursor-pointer flex justify-center items-center">
              <FaRegHeart color="white" size={20} />
            </div>
            {/* <p className="absolute  -top-1 -right-2 bg-red-600 text-white rounded-full  w-4 h-4 text-xs flex justify-center items-center">
              {userProfile?.total_wishlist_item ?? 0}
            </p> */}
          </div>
          <div
            // onClick={() => navigate("/cart")}
            onClick={() => setOpenCartDrawer(true)}
            className="flex font-futura relative flex-col justify-center items-center gap-y-1"
          >
            <div className="h-8 cursor-pointer flex justify-center items-center">
              <FaCartPlus color="white" size={20} />
            </div>
            {/* <p className="cursor-pointer text-black">&nbsp;</p> */}
            <p className="absolute  -top-1 -right-2 bg-green-600 text-white rounded-full w-4 h-4 text-xs flex justify-center items-center">
              {userProfile?.total_cart_item ?? 0}
            </p>
          </div>
        </div>
      </div>
      <div>
        <div
          style={{ zIndex: 99 }}
          className="bg-white hidden  fixed top-[100px] lg:top-[111px] h-14  w-full  gap-x-4 px-14 py-0   md:flex justify-start items-center"
        >
          {/* {categoryList.map((i, index) => {
            return i.child_category.length === 0 ? (
              <p
                style={{ transition: "all 0.8s ease" }}
                onClick={() => navigate("/category", { state: { id: i.id } })}
                className="text-black cursor-pointer text-sm font-medium capitalize text-center"
              >
                {i.name}
              </p>
            ) : (
              <Dropdown
                overlayClassName="categoryDropDown"
                overlayStyle={{ width: "500px" }}
                arrow={false}
                placement="bottomCenter"
                menu={{ items: getCategoryDropdown(i?.child_category) }}
              >
                <div className="flex justify-center items-center gap-x-1">
                  <p
                    key={index}
                    className="text-black cursor-pointer text-sm font-medium capitalize text-center"
                  >
                    {i.name}
                  </p>
                  <p className="font-medium text-sm text-black">
                    <IoChevronDown />
                  </p>
                </div>
              </Dropdown>
            );
          })} */}
          <div onClick={() => setOpenCategoryDrawer(true)}>
            <div className="flex justify-start cursor-pointer items-center gap-x-4">
              <GiHamburgerMenu />
              <p className="font-text underlineText  relative font-semibold">
                All categories
              </p>
            </div>
          </div>
          <div onClick={() => navigate("/")}>
            <p className="font-text underlineText cursor-pointer relative font-semibold">
              Home
            </p>
          </div>
          <div className="lg:flex justify-around hidden gap-x-5  w-9/12 overflow-x-scroll items-center">
            {sidebarCategory.map((i, index) => (
              <p
                onClick={() => {
                  navigate("/category", { state: { id: i.id, completed: i } });
                  dispatch(setSelectedCategory(i.id))
                  dispatch(
                    clearFilter({
                      price: [],
                      rating: [],
                      brandList: [],
                      category: null,
                    })
                  );
                }}
                className={`font-text  capitalize ${selectedCategory===i.id?"text-primary":"text-gray-600"}  underlineText cursor-pointer relative font-semibold`}
                key={index}
              >
                {trimString(i.name)}
              </p>
            ))}
          </div>
        </div>
      </div>
      <div className=" w-full mt-32 md:mt-48  relative max-h-full min-h-screen">
        {children}
      </div>
      {addressModalState && <AddressDrawer />}
      {loginState && <LoginModal />}
      {signUpstate && <SignupModal />}
      <SidebarDrawer
        categoryList={sidebarCategory}
        open={openSidebarDrawer}
        setOpen={setOpenSidebarDrawer}
      />
      {/* {openSearchDrawer && ( */}
      <SearchDrawer open={openSearchDrawer} setIsOpen={setOpenSearchDrawer} />
      {/* )}  */}
      {openCartDrawer && (
        <CartDrawer open={openCartDrawer} setOpen={setOpenCartDrawer} />
      )}
      {openCategpryDrawer && (
        <CategoryDrawer
          category={sidebarCategory}
          open={openCategpryDrawer}
          setOpen={setOpenCategoryDrawer}
        />
      )}
    </>
  );
};

export default DefaultLayout;
