import React, { useState } from 'react'
import { Button, Form, Input } from "antd";
import { ChangeUserPassword } from '../../ApiServices/Apifun';
import { dispatchtoast } from '../../Utils';


const ChangePassword = () => {
    const [btnLoading, setBtnLoading] = useState(false);
  const [form] = Form.useForm();
  const validatePassword = (rule, value) => {
    const { getFieldValue } = form;
    if (value && value !== getFieldValue("newPassword")) {
      return Promise.reject("The passwords do not match!");
    }
    return Promise.resolve();
  };
  const handleFinish = (val) => {
    setBtnLoading(true);
    const formdata = new FormData();
    Object.keys(val).map((key) => formdata.append(key, val[key]));
    ChangeUserPassword(formdata)
      .then((res) => {
        if (res.status) {
          dispatchtoast(res.message);
          form.resetFields()
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };
  return (
    <div className="w-full bg-white shadow-sm shadow-gray-400 p-4 rounded-md  flex justify-start items-start">
    <Form
      form={form}
      name="basic"
      initialValues={{
        remember: true,
      }}
      onFinishFailed={(err) => console.log(err)}
      onFinish={(val) => handleFinish(val)}
    >
      <Form.Item
        name={`oldPassword`}
        label={"Old Password"}
        className="w-full"
        labelCol={{ span: 24 }}
        rules={[
          {
            required: true,
            message: "Enter old password!",
          },
          {
            whitespace: true,
            message: "Field cannot contain only blank spaces",
          },
        ]}
      >
        <Input.Password
          placeholder="Enter old password"
          name="oldPassword"
          className="outline-none text-xs rounded-none border  w-full border-gray-200 focus:border-primary  h-9 px-2 "
        />
      </Form.Item>
      <Form.Item
        name={`newPassword`}
        label={"New password"}
        className="w-full"
        labelCol={{ span: 24 }}
        rules={[
          {
            required: true,
            message: "Enter new password!",
          },
          {
            whitespace: true,
            message: "Field cannot contain only blank spaces",
          },
        ]}
      >
        <Input.Password
          placeholder="Enter new password"
          name="newPassword"
          className="outline-none text-xs rounded-none border  w-full border-gray-200 focus:border-primary  h-9 px-2 "
        />
      </Form.Item>{" "}
      <Form.Item
        name={`confirmNewPassword`}
        label={"Confirm Password"}
        labelCol={{ span: 24 }}
        className="w-full"
        dependencies={["new_password"]}
        rules={[
          {
            required: true,
            message: "Please confirm your password!",
          },
          { validator: validatePassword },
          {
            whitespace: true,
            message: "Field cannot contain only blank spaces",
          },
        ]}
      >
        <Input.Password
          placeholder="Confirm your password"
          name="confirmNewPassword"
          className="outline-none text-xs rounded-none border  w-full border-gray-200 focus:border-primary  h-9 px-2 "
        />
      </Form.Item>{" "}
      <Form.Item>
        <Button
        style={{color:"white"}}
          loading={btnLoading}
          className="w-full font-semibold h-10 bg-primary hover:bg-primaryhover text-white mt-2 border-none"
          htmlType="submit"
        >
          Save
        </Button>
      </Form.Item>{" "}
    </Form>
  </div>
  )
}

export default ChangePassword