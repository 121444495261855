import { Button, Checkbox, Modal } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { setFilters } from "../../Redux/userSlice";

const BrandModal = ({ setFilterdata, filters, open, setOpen, allBrands }) => {
  const dispatch = useDispatch();
  const handleSelectAll = () => {
    let data = { ...filters };
    data.brandList = allBrands.map((i) => i.id);
    setFilterdata(data);
  };
  const handleClearAll = () => {
    let data = { ...filters };
    data.brandList = [];
    setFilterdata(data);
  };
  const handleApply = () => {
    dispatch(setFilters(filters));
    setOpen(false)
  };
  return (
    <>
      <Modal
        title={
          <p className="font-heading font-medium text-3xl">Choose BRANDS</p>
        }
        footer={null}
        open={open}
        width={800}
        onCancel={() => setOpen(false)}
      >
        <div className="max-h-[300px] overflow-scroll">
          <div className="grid grid-cols-3 md:grid-cols-4 place-items-start gap-y-3">
            {allBrands.map((i) => {
              return (
                <Checkbox
                  onChange={(e) => {
                    let data = { ...filters };
                    if (data.brandList.includes(e.target.value)) {
                      data.brandList = data.brandList.filter(
                        (i, index) => i !== e.target.value
                      );
                    } else {
                      data.brandList = [...data.brandList, e.target.value];
                    }
                    setFilterdata(data);
                  }}
                  value={i.id}
                  checked={filters.brandList.includes(i.id)}
                >
                  <p className="text-xs uppercase">{i.name}</p>
                </Checkbox>
              );
            })}
          </div>
        </div>
        <div className="flex justify-between mt-2 items-center">
          <div className="flex justify-center items-center gap-x-3">
            <p
              onClick={() => handleSelectAll()}
              className="font-heading cursor-pointer"
            >
              Select All
            </p>
            <p onClick={handleClearAll} className="font-heading cursor-pointer">
              Clear All
            </p>
          </div>

          <Button
            onClick={() => handleApply()}
            style={{ color: "white" }}
            className="bg-black w-24 text-center text-white border-none hover:border-none rounded-none"
          >
            Apply
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default BrandModal;
