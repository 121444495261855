import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  AddToCart,
  GetProdDetail,
  addWishList,
  deleteWishList,
} from "../../ApiServices/Apifun";
import {  createImageUrl, dispatchtoast, getprice } from "../../Utils";
// import { Carousel } from "react-carousel-minimal";
import { Select, Spin } from "antd";
import { FaCartArrowDown, FaHeart } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { setOpenLoginModal } from "../../Redux/commonSlice";
import ProductDetails from "./ProductDetails";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Images } from "../../ImageController/Index";

const Productdesc = ({ productId }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const [productDetail, setProductDetail] = useState(null);
  const [productOptionDetail, setProductOptionDetail] = useState([]);
  const [allDetail, setAllDetail] = useState(null);
  const [price, setPrice] = useState("");
  const [pageLoading, setPageLoading] = useState(false);
  const [shownImages, setShowImages] = useState([]);
  const [allOptionImage, setAllOptionImage] = useState([]);
  const [optionArr, setOptionArr] = useState({});
  const [selectedData] = useState({
    prod_id: productDetail?.id,
    prod_opt_id: [],
    quantity: 1,
    total_amount: "",
  });
  const isLoggedIn = useSelector((state) => state.user.userDetail);
  // const userProfile = useSelector((state) => state.user.userProfile);

  const getProductDetail = () => {
    setPageLoading(true);
    GetProdDetail(
      `/${productId || location.state?.id || encodeURIComponent(params.slug)}`
    )
      .then((res) => {
        if (res.status) {
          setProductDetail(res.data.productDetail);
          let createArr = Object.keys(res.data.productOptionDetail).map(
            (key) => key
          );
          let a = {};
          for (let index = 0; index < createArr.length; index++) {
            a = { ...a, [createArr[index]]: "" };
          }
          setOptionArr(a);
          setAllDetail(res.data);
          setProductOptionDetail(res.data.productOptionDetail);
          setPrice(res.data.productDetail?.min_selling_price);
          // setDefaultImages(res.data.defaultImageDetail);
          setShowImages(res.data.defaultImageDetail[0]?.image);
          setAllOptionImage(res.data.defaultImageDetail);
        }
      })
      .catch((err) => {
        console.log(err);
        if (!err.response?.data?.status) {
          navigate("/");
        }
      })
      .finally(() => setPageLoading(false));
  };
  useEffect(() => {
    if (!location.state && !params.slug && !productId) {
      navigate("/");
    } else {
      getProductDetail();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleAddToCart = () => {
    if (!isLoggedIn) {
      dispatch(setOpenLoginModal(true));
    } else if (Object.values(optionArr).includes("")) {
      dispatchtoast("Please select all options", true);
    } else {
      const payload = {
        ...selectedData,
        prod_id: productDetail?.id,
        prod_opt_id: Object.values(optionArr).map((i) => i.id),
        total_amount: price * selectedData.quantity,
      };
      AddToCart(payload)
        .then((res) => {
          if (res.status) {
            navigate("/cart");
          }
        })
        .catch((err) => console.log(err));
    }
  };
  useEffect(() => {
    setPrice(getprice(productDetail?.min_selling_price, optionArr));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionArr]);
  const handleAddToFavourite = (data) => {
    addWishList({
      record_id: data,
    })
      .then((res) => {
        if (res.status) {
          let data = { ...allDetail };
          data = { ...data, is_favourite: 1 };
          // dispatch(ManageWishlistCount(userProfile,"add"))
          setAllDetail(data);
          dispatchtoast(res.message);
        }
      })
      .catch((err) => console.log(err));
  };
  const handleRemoveFromFavourite = (data) => {
    deleteWishList({
      record_id: [data],
    })
      .then((res) => {
        if (res.status) {
          let data = { ...allDetail };
          data = { ...data, is_favourite: 0 };
          // dispatch(ManageWishlistCount(userProfile,"remove"))
          setAllDetail(data);
          dispatchtoast(res.message);
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      <Spin spinning={pageLoading}>
        <div className="md:container md:mx-auto mx-4 mt-20 select-none">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-2 h-full  place-items-start">
            {/* <Slider className="citySlider " {...settings}>
            {[1, 2, 3, 4, 5, 5, 5, 5].map((i) => (
              <div className=""> */}
            <div className="w-full">
              <div className=" w-full px-6 relative  m-auto">
                <LazyLoadImage
                  onError={(err) => {
                    err.target.src = Images.NoImage;
                    err.target.alt = "No Image";
                  }}
                  src={createImageUrl(shownImages)}
                  // src={Images.Jacket}
                  crossOrigin="anonymous"
                  alt="Product Image"
                  className=" shadow-lg shadow-gray-400 max-h-[400px] w-full"
                />
                        <div
                  onClick={() =>
                    isLoggedIn
                      ? !allDetail?.is_favourite
                        ? handleAddToFavourite(
                            productDetail.id,
                            getProductDetail
                          )
                        : handleRemoveFromFavourite(
                            productDetail.id,
                            getProductDetail
                          )
                      : dispatch(setOpenLoginModal(true))
                  }
                  className={`h-8 ${
                    allDetail?.is_favourite ? "bg-red-600" : "bg-white"
                  } shadow-md shadow-gray-500 absolute top-2 hover:scale-105 transition-all right-8 rounded-full w-8 cursor-pointer flex justify-center items-center`}
                >
                  <FaHeart 
                    color={allDetail?.is_favourite ? "white" : "lightgray"}
                    size={15}
                  />
                </div>
              </div>
              <div className="flex max-w-[400px] overflow-x-scroll justify-center m-auto mt-4 items-center gap-x-3">
                {allOptionImage.map((i, index) => (
                  <LazyLoadImage
                    onError={(err) => {
                      err.target.src = Images.NoImage;
                      err.target.alt = "No Image";
                    }}
                    crossOrigin="anonymous"
                    src={createImageUrl(i.image)}
                    // src={Images.Model}
                    onClick={() => setShowImages(i.image)}
                    className="hover:opacity-70 transition-all shadow-sm shadow-gray-300 cursor-pointer p-1 w-20 h-20"
                  />
                ))}
              </div>
              {/* <div
              style={{
                padding: "0 20px",
              }}
            >
              <Carousel
                data={data}
                time={2000}
                // width="40px"
                // height="500px"
                captionStyle={captionStyle}
                captionPosition="bottom"
                automatic={true}
                dots={true}
                pauseIconColor="white"
                pauseIconSize="40px"
                slideBackgroundColor="darkgrey"
                slideImageFit="cover"
                thumbnails={true}
                thumbnailWidth="100px"
                style={{
                  textAlign: "center",
                  maxWidth: "400px",
                  // maxHeight: "300px",
                  margin: "auto",
                }}
              />
            </div> */}
            </div>
            <div className="w-full h-full">
              <div className="flex justify-between items-center">
                <p className="text-gray-700 font-text capitalize text-4xl">
                  {productDetail?.product_name}
                </p>
        
              </div>
              {/* <div
                className="text-lg mt-2 capitalize text-gray-400"
                dangerouslySetInnerHTML={{ __html: productDetail?.description }}
              ></div> */}
              <p className="text-lg mt-2 text-gray-400 text-justify">
                {productDetail?.description}
            </p>
              <p className="text-lg font-bold text-gray-700 mt-2">
                ₹ {Number(price).toFixed(2) * selectedData.quantity ?? 0}
                {/* <span className="text-red-500">(60%off)</span> */}
              </p>
              <p className="text-red-500 font-semibold mt-2">
                Inclusive of all taxes
              </p>
              <div className="mt-2  w-1/2 flex gap-x-5 justify-start items-center">
                {Object?.keys(productOptionDetail)?.map((key, index) => {
                  return (
                    <>
                      {key.toLowerCase() === "color".toLowerCase() ? (
                        <div className="flex gap-x-5 justify-start items-center">
                          <p className="text-sm capitalize text-gray-800">
                            {key}{" "}
                          </p>
                          <div className="flex justify-start gap-4  items-center">
                            {productOptionDetail[key].length !== 0 &&
                              productOptionDetail[key].map((i, index) => {
                                return (
                                    <div key={index} className="flex flex-col justify-center items-center">
                                    <div
                                      className={`${
                                        optionArr[key].id === i.id
                                          ? "border-2 border-gray-600 "
                                          : ""
                                      }rounded-full  p-[2px] `}
                                    >
                                      <div
                                        onClick={() => {
                                          setAllOptionImage(i.image_data);
                                          setShowImages(i.image_data[0]?.image);
                                          setOptionArr({
                                            ...optionArr,
                                            [key]: {
                                              type: i.price_type,
                                              price: i.price,
                                              id: i.id,
                                            },
                                          });
                                        }}
                                        style={{
                                          background: i.color_code,
                                        }}
                                        className={` rounded-full text-xs  font-semibold  cursor-pointer  w-6  h-6 flex justify-center items-center`}
                                      ></div>
                                    </div>
                                      <p className="text-xs capitalize text-center text-gray-600">{i.option_value_name}</p>
                                    </div>
                                );
                              })}
                          </div>
                        </div>
                      ) : (
                        <div className="flex gap-x-5 w-full justify-start items-center">
                          <p className="text-sm capitalize text-gray-400">
                            {key}{" "}
                          </p>
                          <div className="flex w-full justify-start flex-wrap gap-8 items-center">
                            <Select
                              placeholder="Select size"
                              labelInValue
                              onChange={(val) => {
                                // setShowImages(val.key.image_data);
                                setOptionArr({
                                  ...optionArr,
                                  [key]: {
                                    type: val.key.price_type,
                                    price: val.key.price,
                                    id: val.value,
                                  },
                                });
                                // setSize({
                                //   type: val.key.price_type,
                                //   price: val.key.price,
                                //   id: val.value,
                                // });
                              }}
                              options={
                                productOptionDetail[key].length !== 0 &&
                                productOptionDetail[key].map((i, index) => {
                                  return {
                                    label: i.option_value_name,
                                    value: i.id,
                                    key: i,
                                  };
                                })
                              }
                              className="w-full"
                            />
                          </div>
                        </div>
                      )}
                    </>
                  );
                })}
              </div>

              <div className="flex justify-start items-center gap-4 mt-8">
                <div
                  onClick={handleAddToCart}
                  className="flex bg-black rounded-sm gap-2 p-3 cursor-pointer transition-all hover:px-8 text-white font-semibold justify-center items-center"
                >
                  <FaCartArrowDown />
                  <p>Add to cart</p>
                </div>
                {/* <div className="flex bg-green-800 rounded-sm p-3 gap-2 cursor-pointer hover:px-8 transition-all text-white font-semibold justify-center items-center">
                <FaCartArrowDown />
                <p>Buy Now</p>
              </div> */}
              </div>
              {allDetail && (
                <ProductDetails
                  apiCall={getProductDetail}
                  productDetail={allDetail}
                />
              )}
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default Productdesc;
