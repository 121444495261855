export const Authendpoint = {
  Register: "user/signup",
  Login: "user/login",
  OtpVerify: "auth/verify-otp",
  resendOtp: "auth/resend-otp",
};
export const Globalendpoints = {
  //masters
  getStates:"user/get-states/",
  getCountry:"user/get-countries",
  userDetail:"user/detail/auto-fetch",
 getAllData:"user/portal-web",
 bySectionId:"user/section/",
 getProdDetail:"user/get-prod-detail",
 addToCart:"user/cart/add",
 getCartList:"user/cart/get-list",
 updateQuantity:"user/cart/update-quantity",
 deleteCart:"user/cart/delete",
 checkProductAvailability:"user/check-product-availability",
 getShippingAddress:"user/shipping-address/get-list",
 addShippingAddress:"user/shipping-address/add",
 editShippingAddress:"user/shipping-address/edit",
 particularAddDetail:"user/shipping-address/form",
 deleteShippingAddress:"user/shipping-address/delete",
 setdefaultShipping:"user/shipping-address/set-up-default/",
 checkout:"user/checkout",
 chckoutCart:"user/checkout/get-list",
 confirmOrder:"user/confirm-order",
 orderList:"user/my-order-list",
 orderDetail:"user/order-detail/",

 cancelOrder:"user/cancel-order/",

 updateProfile:"user/detail/set-user-profile",
 updateProfileImage:"user/detail/set-user-image",

 searchProduct:"user/search",
 categoryList:"user/category-list",
 ProductListByCategory:"user/cate-prod-list",

 wishlist:"user/favorite-item/get-list",
 addWishList:"user/favorite-item/add",
 deleteWishList:"user/favorite-item/delete",

 AddReview:"user/review/add",
 EditReview:"user/review/edit",

 changePassword:"user/detail/change-password",
 getPromoCode:"user/promo-code/get-list",
 verifyPromoCode:"user/promo-code/verify",

 brandList:"user/brand-list"

};
