import BannerImage from "../Assets/BannerImage.jpg";
import BannerImage2 from "../Assets/BannerImage2.jpg";
import BannerImage3 from "../Assets/BannerImage3.jpg";

import Electric from "../Assets/electric.jpg";
import Interior from "../Assets/interior.jpg";
import Chair from "../Assets/chair.jpg";
import Mobile from "../Assets/mobile.jpg";
import Jacket from "../Assets/jacket.jpeg";
import washingmachine from "../Assets/washingmachine.webp";
import pc from "../Assets/pc.webp";
import perfume from "../Assets/perfume.webp";
import shoes from "../Assets/shoes.webp";
import earbuds from "../Assets/earbuds.webp";
import womenshoes from "../Assets/womenshoes.webp";
import Login from "../Assets/LoginImage.svg";
import SignUpImage from "../Assets/signupImage.svg";
import EmptyCart from "../Assets/empty-cart.png";
import Model from "../Assets/model.webp";
import SuccessIcon from "../Assets/success.png";
import NoOrder from "../Assets/no-order.png";
import NoAddress from "../Assets/noaddress.png";
import NoImage from "../Assets/No-Image-Placeholder.png";
import NoFavourite from "../Assets/NoFabourite.jpg";
import WriteReview from "../Assets/writeReview.png";
import Logo from "../Assets/Logo.png";
import ChildBanner from "../Assets/childBanner.svg";
import Pending from "../Assets/pending.png";

export const Images = {
  Pending,
  ChildBanner,
  Logo,
  BannerImage,
  BannerImage2,
  BannerImage3,
  Electric,
  Interior,
  EmptyCart,
  Chair,
  Mobile,
  Jacket,
  washingmachine,
  pc,
  perfume,
  shoes,
  earbuds,
  womenshoes,
  Login,
  SignUpImage,
  Model,
  NoOrder,
  NoImage,
  NoFavourite,
  WriteReview,
  SuccessIcon,
  NoAddress,
};
