import React, { useEffect, useState } from "react";
import { Images } from "../../ImageController/Index";
import Footer from "../../components/Footer";
import { Button, Skeleton, Spin } from "antd";
import {
  DeleteCart,
  GetCartList,
  UpdateQuantity,
} from "../../ApiServices/Apifun";
import { createImageUrl, dispatchtoast } from "../../Utils";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUserAllProfile } from "../../Redux/userSlice";
import {
  setOpenAddressModal,
  setOpenLoginModal,
} from "../../Redux/commonSlice";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { IoIosClose } from "react-icons/io";

const Cart = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cartList, setCartList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [listSkeleton, setListSkeleton] = useState(false);

  const userProfile = useSelector((state) => state.user.userProfile);
  const isLoggedIn = useSelector((state) => state.user.userDetail);
  const GetCartData = () => {
    // setListSkeleton(true);
    GetCartList()
      .then((res) => {
        if (res.status) {
          setCartList(res.data);
          const total = res.data
            .filter((i) => i.is_out_of_stock !== 1)
            .reduce((a, b) => {
              a = a + b.total_qty_price;
              return a;
            }, 0);
          setTotalAmount(total);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setListSkeleton(false));
  };

  useEffect(() => {
    if (isLoggedIn) {
      GetCartData();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubtract = (data, index) => {
    if (data.product_qty > 1) {
      setLoading(true);

      const payload = {
        record_id: data.cart_id,
        type: "dec",
      };

      UpdateQuantity(payload)
        .then((res) => {
          if (res.status) {
            GetCartData();
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    }
  };

  const handleAdd = (data, index) => {
    if (data.product_qty <= 4) {
      const payload = {
        record_id: data.cart_id,
        type: "inc",
      };
      UpdateQuantity(payload)
        .then((res) => {
          if (res.status) {
            GetCartData();
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    } else {
      dispatchtoast("You can only add 5 item at a time 😊.");
    }
  };
  const handleDelete = (data) => {
    let temp = { ...userProfile };
    setLoading(true);
    const payload = {
      record_id: [data.cart_id],
    };
    DeleteCart(payload)
      .then((res) => {
        if (res.status) {
          dispatchtoast(res.message);
          temp = { ...temp, total_cart_item: temp.total_cart_item - 1 };
          dispatch(setUserAllProfile(temp));
          GetCartData();
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };
  const goToCheckOut = () => {
    if (!isLoggedIn) {
      dispatch(setOpenLoginModal(true));
    } else {
      if (userProfile.total_shipping_address === 0) {
        dispatch(setOpenAddressModal(true));
      } else if (
        cartList.find(
          (i) =>
            i.is_created_user_available !== 1 && i.is_shop_user_available !== 1
        )
      ) {
        dispatchtoast("Remove item which seller is deactivate", true);
      } else if (cartList.find((i) => i.is_all_options_available !== 1)) {
        dispatchtoast("Remove item which is not available", true);
      } else if (cartList.find((i) => i.is_out_of_stock === 1)) {
        dispatchtoast("Remove out of stock items", true);
      } else {
        if (cartList.length !== 0 && totalAmount !== 0) {
          setBtnLoading(true);
          navigate("/checkout");
        } else {
          dispatchtoast("Add some product in cart before checking out🙌");
        }
      }
    }
  };

  return (
    <>
      <div className="lg:container lg:mx-auto mx-4   select-none">
       <div className="flex justify-center items-center">
       <p className="text-3xl md:text-5xl sectionHeading relative text-textColor font-heading rounded-sm flex justify-center items-center  cursor-pointer ">
          Cart-items
        </p>
        </div>
        {listSkeleton ? (
          <div className="mt-2">
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
          </div>
        ) : (
          <div
            className={`grid mt-4 grid-cols-1 ${
              cartList.length === 0 ? "lg:grid-cols-1" : "lg:grid-cols-3"
            } lg:gap-x-8 place-items-start`}
          >
            <div className="col-span-2 max-h-[400px] overflow-scroll w-full ">
              <Spin spinning={loading}>
                <div className="lg:grid hidden grid-cols-5 mt-4 font-bold font-heading place-items-center">
                  <div className="col-span-2">
                    <p>Product</p>
                  </div>
                  <div>
                    <p>Price</p>
                  </div>
                  <div>
                    <p>Quantity</p>
                  </div>
                  <div>
                    <p>Subtotal</p>
                  </div>
                </div>
                {/* <div className="w-full hidden lg:block border border-gray-200 mt-4 h-[1px]"></div> */}
                {
                  // listSkeleton ? (
                  //   <>
                  //     <Skeleton active={true} className="mt-2" />
                  //     <Skeleton active={true} className="mt-2" />
                  //   </>
                  // ) :
                  cartList.length !== 0 ? (
                    cartList.map((i, index) => {
                      const image = i.productOptionDetail.map((i) => {
                        return {
                          ...i,
                          option_images:
                            typeof i.option_images == "string"
                              ? JSON.parse(i.option_images)
                              : i.option_images,
                        };
                      });
                      return (
                        <>
                          <div className="lg:grid hidden relative grid-cols-5 mt-2 font-semibold place-items-center">
                            <div className="col-span-2 w-full">
                              <div className="flex w-full justify-center gap-8 items-center">
                                <LazyLoadImage
                                  onError={(err) => {
                                    err.target.src = Images.NoImage;
                                    err.target.alt = "No Image";
                                  }}
                                  src={createImageUrl(
                                    image.length !== 0
                                      ? image.filter(
                                          (i) => i.option_name === "color"
                                        )[0]?.option_images[0]
                                      : i.defaultImageDetail[0].image
                                  )}
                                  alt="itemImage"
                                  crossOrigin="anonymous"
                                  className="w-24 m-auto rounded-lg"
                                />
                                <div className="w-full">
                                  <p
                                    className="cursor-pointer"
                                    onClick={() =>
                                      navigate(`/productDesc/${i.slug}`, {
                                        state: { id: i.slug },
                                      })
                                    }
                                  >
                                    {" "}
                                    {i.product_model}
                                  </p>
                                  <p className="text-xs"> {i.product_name}</p>
                                  {i.productOptionDetail.map((i, index) => (
                                    <p className="capitalize text-gray-700 text-xs">
                                      {i.option_name}: {i.option_value_name}
                                    </p>
                                  ))}
                                </div>
                              </div>
                            </div>
                            {i.is_shop_user_available !== 1 &&
                            i.is_created_user_available !== 1 ? (
                              <div className="col-span-3">
                                <p className="text-red-500 text-xl font-semibold">
                                  Seller not active
                                </p>
                              </div>
                            ) : i.is_all_options_available !== 1 ? (
                              <div className="col-span-3">
                                <p className="text-red-500 text-xl font-semibold">
                                  Product Unavailable
                                </p>
                              </div>
                            ) : i.is_out_of_stock === 1 ? (
                              <div className="col-span-3">
                                <p className="text-red-500 text-xl font-semibold">
                                  OUT OF STOCK
                                </p>
                              </div>
                            ) : (
                              <>
                                <div>
                                  <p>₹ {i.total_qty_price}</p>
                                </div>
                                <div>
                                  <div className="flex justify-center items-center gap-x-4 ">
                                    <p
                                      onClick={() => handleSubtract(i, index)}
                                      className="   bg-black text-white px-4 py-1 text-lg text-center cursor-pointer"
                                    >
                                      -
                                    </p>
                                    <p>{i.product_qty}</p>
                                    <p
                                      onClick={() => handleAdd(i, index)}
                                      className=" bg-black text-white px-4 py-1 text-lg text-center   cursor-pointer"
                                    >
                                      +
                                    </p>
                                  </div>
                                </div>
                                <div>
                                  <p>₹ {i.total_qty_price}</p>
                                </div>
                              </>
                            )}

                            <div
                              onClick={() => handleDelete(i)}
                              className="absolute right-0 cursor-pointer top-0"
                            >
                              <IoIosClose size={30} color="black" />
                            </div>
                          </div>
                          <div className="w-full hidden lg:block border border-gray-200 mt-4 h-[1px]"></div>
                          <div className="shadow-md block  lg:hidden relative shadow-gray-400 py-10">
                            <div className="flex justify-start items-center gap-x-5">
                              <LazyLoadImage
                                onError={(err) => {
                                  err.target.src = Images.NoImage;
                                  err.target.alt = "No Image";
                                }}
                                src={createImageUrl(
                                  image.length !== 0
                                    ? image.filter(
                                        (i) => i.option_name === "color"
                                      )[0]?.option_images[0]
                                    : i.defaultImageDetail[0].image
                                )}
                                // src={Images.pc}
                                alt="itemImage"
                                crossOrigin="anonymous"
                                className="w-24 ml-4 rounded-lg"
                              />
                              <div>
                                <p
                                  className="font-semibold cursor-pointer"
                                  onClick={() =>
                                    navigate(`/productDesc/${i.slug}`, {
                                      state: { id: i.slug },
                                    })
                                  }
                                >
                                  {" "}
                                  {i.product_model}
                                </p>
                                <p className="text-xs"> {i.product_name}</p>
                                {i.productOptionDetail.map((i, index) => (
                                  <p className="capitalize text-gray-700 text-xs">
                                    {i.option_name}: {i.option_value_name}
                                  </p>
                                ))}
                              </div>
                            </div>
                            <div className="flex justify-around mt-4 items-center">
                              {i.is_shop_user_available !== 1 &&
                              i.is_created_user_available !== 1 ? (
                                <div className="col-span-3">
                                  <p className="text-red-500 text-xl font-semibold">
                                    Seller not active
                                  </p>
                                </div>
                              ) : i.is_all_options_available !== 1 ? (
                                <div className="col-span-3">
                                  <p className="text-red-500 text-xl font-semibold">
                                    Product Unavailable
                                  </p>
                                </div>
                              ) : i.is_out_of_stock === 1 ? (
                                <div className="col-span-3">
                                  <p className="text-red-500 text-xl font-semibold">
                                    OUT OF STOCK
                                  </p>
                                </div>
                              ) : (
                                <div>
                                  <div className="flex justify-center items-center gap-x-4 ">
                                    <p
                                      onClick={() => handleSubtract(i, index)}
                                      className="   bg-black text-white px-4 py-1 text-lg text-center cursor-pointer"
                                    >
                                      -
                                    </p>
                                    <p>{i.product_qty}</p>
                                    <p
                                      onClick={() => handleAdd(i, index)}
                                      className=" bg-black text-white px-4 py-1 text-lg text-center   cursor-pointer"
                                    >
                                      +
                                    </p>
                                  </div>
                                </div>
                              )}

                              <div>
                                <p>₹ {i.total_qty_price}</p>
                              </div>
                            </div>
                            <div
                              onClick={() => handleDelete(i)}
                              className="absolute right-2 cursor-pointer top-2"
                            >
                              <IoIosClose size={30} color="black" />
                            </div>
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <div className="flex justify-center items-center">
                      <div>
                        <LazyLoadImage
                          onError={(err) => {
                            err.target.src = Images.NoImage;
                            err.target.alt = "No Image";
                          }}
                          src={Images.EmptyCart}
                          alt="empty-cart"
                          className="w-36"
                        />
                        <p
                          onClick={() => navigate("/")}
                          className="text-center bg-black p-3 rounded-sm transition-all  cursor-pointer font-semibold text-white "
                        >
                          Explore Products
                        </p>
                      </div>
                    </div>
                  )
                }
              </Spin>
            </div>
            {cartList.length !== 0 ? (
              <div className="w-full mt-5 md:mt-0">
                <div className="border p-4 bg-[#f8f8f8] border-gray-100  rounded-lg ">
                  <p className="font-semibold text-center text-3xl font-heading">
                    Cart Totals
                  </p>
                  <div className="flex mt-4 justify-between items-center">
                    <p className="text-sm font-bold">Subtotal</p>
                    <p className="font-bold font-mono">₹ {Number(totalAmount)}</p>
                  </div>
                  <div className="flex mt-4 justify-between items-center">
                    <p className="text-sm font-bold">Total</p>
                    <p className="font-bold font-mono">₹ {Number(totalAmount)}</p>
                  </div>
                  <div className="flex w-full mt-8 justify-between items-center">
                    <Button
                      loading={btnLoading}
                      onClick={goToCheckOut}
                      style={{ color: "white" }}
                      className="w-full bg-black font-bold rounded-sm text-sm text-center h-10 text-white border-none hover:border-none outline-none hover:outline-none"
                    >
                      Checkout
                    </Button>
                  </div>
                  <p
                    onClick={() => navigate("/")}
                    className="text-center text-sm underline text-black mt-4"
                  >
                    Explore Product
                  </p>
                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Cart;
