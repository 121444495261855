import Cart from "../Screens/Cart/Cart";
import Category from "../Screens/Category/Category";
import Checkout from "../Screens/Checkout/Checkout";
import Collection from "../Screens/Collections/Collection";
import Favourites from "../Screens/Favourites/Favourites";
import Home from "../Screens/Home/Home";
import MyOrders from "../Screens/MyOrders/MyOrders";
import OrderDetail from "../Screens/MyOrders/OrderDetail";
import OrderSuccess from "../Screens/OrderSuccess/OrderSuccess";
import Productdesc from "../Screens/ProductDesc/Productdesc";
import Profile from "../Screens/Profile";
import CommerceAuth from "./CommerceAuth";

export const PrivateRoutes = () => {
  return [
    {
      element: <CommerceAuth />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/profile",
          element: <Profile />,
        },
        {
          path: "/productDesc/:slug",
          element: <Productdesc />,
        },
        {
          path: "/cart",
          element: <Cart />,
        },
        {
          path: "/checkout",
          element: <Checkout />,
        },
        {
          path: "/collection",
          element: <Collection />,
        },
        {
          path: "/orders",
          element: <MyOrders />,
        },
        {
          path: "/order-success",
          element: <OrderSuccess />,
        },
        {
          path: "/orderDetail",
          element: <OrderDetail />,
        },
        {
          path: "/favourites",
          element: <Favourites />,
        },
        {
          path: "/category",
          element: <Category />,
        },
      ],
    },
  ];
};
