import { DatePicker, Form, Modal } from "antd";
import React from "react";
import { Images } from "../ImageController/Index";
import CommonInput from "../CommmonComponents/CommonInput";
import Commonbutton from "../CommmonComponents/Commonbutton";
import { useDispatch, useSelector } from "react-redux";
import { setOpenSignUpModal } from "../Redux/commonSlice";
import { userRegister } from "../ApiServices/Apifun";
import { dispatchtoast, emailPattern, mobilePattern } from "../Utils";
import { LazyLoadImage } from "react-lazy-load-image-component";

const SignupModal = () => {
  const dispatch = useDispatch();
  const openModal = useSelector((state) => state.common.openSignUpModal);

  const handleRegister = (val) => {
    let data = { ...val};
    if(val.dob){
      data.dob=(new Date(`"${val.dob.$d}"`).getTime()/1000).toString()
    }else{
      data.dob=null
    }
    userRegister(data)
      .then((res) => {
        if(res.status){
          dispatchtoast(res.message)
          dispatch(setOpenSignUpModal(false))
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <Modal
      width={700}
      footer={null}
      open={openModal}
      onCancel={() => dispatch(setOpenSignUpModal(false))}
    >
      <div className="grid grid-cols-1 sm:grid-cols-2 place-items-center mt-8">
        <div className="hidden sm:block">
          <LazyLoadImage onError={(err)=>{
                              err.target.src=Images.NoImage
                              err.target.alt="No Image"
                            }} loading="lazy" src={Images.SignUpImage} alt="signUpImage" />
        </div>
        <div className="border w-full border-[#DBE7FF] shadow-[0_6px_30px_0px_rgba(0,0,0,0.08)] p-4">
          <p className="text-black text-xl font-semibold font-Roboto">
            Let’s Get Started!{" "}
          </p>
          <p className="text-[##8D8D8D] text-xs mt-2">
            Please Enter your all the Fields
          </p>
          <div className="mt-4">
            <Form
              className="signupForm"
              onFinish={(val) => handleRegister(val)}
              onFinishFailed={(err) => console.log(err)}
            >
              <div className="grid grid-cols-2 gap-x-4  place-items-center">
                <Form.Item
                  name={"first_name"}
                  className="p-0"
                  labelCol={{ span: 24 }}
                  label={
                    <p className="font-medium font-Roboto text-sm m-0 p-0">
                      First Name
                    </p>
                  }
                  rules={[
                    {
                      required: true,
                      message: "First name is required",
                    },
                  ]}
                >
                  <CommonInput name={"first_name"} placeholder={"First Name"} />
                </Form.Item>
                <Form.Item
                  labelCol={{ span: 24 }}
                  label={
                    <p className="font-medium font-Roboto text-sm mb-1">
                      Last Name
                    </p>
                  }
                  name={"last_name"}
                >
                  <CommonInput placeholder={"Last Name"} />
                </Form.Item>
                <Form.Item
                  labelCol={{ span: 24 }}
                  label={
                    <p className="font-medium font-Roboto text-sm mb-1">
                      Date Of Birth
                    </p>
                  }
                  name={"dob"}
                >
                  <DatePicker disabledDate={(current)=>current && current.valueOf() > Date.now()}
                    onChange={(val) => console.log(new Date(val.$d))}
                    placeholder={"DOB"}
                  />
                </Form.Item>{" "}
                <Form.Item
                  labelCol={{ span: 24 }}
                  label={
                    <p className="font-medium font-Roboto text-sm mb-1">
                      Email
                    </p>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Email is required",
                    },
                    {
                      pattern:emailPattern,
                      message:"Enter valid email"
                    }
                  ]}
                  name={"email"}
                >
                  <CommonInput placeholder={"Email"} />
                </Form.Item>
              </div>
              <Form.Item
                labelCol={{ span: 24 }}
                label={
                  <p className="font-medium font-Roboto text-sm mb-1">Mobile</p>
                }
                rules={[
                  {
                    required: true,
                    message: "Mobile is required",
                  },
                  {
                    pattern:mobilePattern,
                    message:"Invalid mobile"
                  }
                ]}
                name={"mobile"}
              >
                <CommonInput placeholder={"Enter Mobile Number"} />
              </Form.Item>
              <Form.Item
                labelCol={{ span: 24 }}
                label={
                  <p className="font-medium font-Roboto text-sm mb-1">
                    Enter Password
                  </p>
                }
                rules={[
                  {
                    required: true,
                    message: "Passowrd is required",
                  },
                ]}
                name={"password"}
              >
                <CommonInput placeholder={"Your Password"} />
              </Form.Item>
              <div>
                <p className="text-[10px] text-textColor font-Roboto">
                  By clicking submit, you agree to{" "}
                  <span className="cursor-pointer text-svgBorder">
                    Terms of Use, Privacy Policy, E-sign & communication
                    Authorization.
                  </span>
                </p>
              </div>
              <Form.Item>
                <div className="flex mt-2 justify-center items-center">
                  <Commonbutton
                    htmltype="submit"
                    className="bg-black border-none text-white focus:outline-none w-[227px] hover:border-none hover:outline-none"
                    text={"Sign Up"}
                  />
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SignupModal;
