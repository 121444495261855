import React, { useEffect, useState } from "react";
import { CancelOrder, GetOrders } from "../../ApiServices/Apifun";
import { Button, Skeleton } from "antd";
import { useNavigate } from "react-router-dom";
import { Images } from "../../ImageController/Index";
import { useSelector } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";
import CommonPagination from "../../CommmonComponents/CommonPagination";
import ConfirmationModal from "../../CommmonComponents/ConfirmationModal";
import { dispatchtoast } from "../../Utils";

const OrderList = ({ status }) => {
  const [orders, setOrders] = useState([]);
  const [orderid, setorderid] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const [listSkeleton, setListSkeleton] = useState(false);
  const [currentPage, setCurrent] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const isLoggedIn = useSelector((state) => state.user.userDetail);
  const [openConfirm, setOpenConfirm] = useState(false);
  const navigate = useNavigate();
  const getAllOrders = () => {
    setListSkeleton(true);
    GetOrders(
      `?type=${
        status === "Processing"
          ? "confirmed"
          : status === "Delivered"
          ? "delivered"
          : "cancelled"
      }&page=${currentPage}&limit=${pageSize}`
    )
      .then((res) => {
        if (res.status) {
          setOrders(res.data);
          setTotalPages(res.totalRecords);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setListSkeleton(false));
  };
  useEffect(() => {
    if (isLoggedIn) {
      getAllOrders();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, currentPage]);
  useEffect(() => {
    setCurrent(1);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);
  const handleCancel = () => {
    setBtnLoading(true);
    setBtnLoading(true);
    CancelOrder(orderid)
      .then((res) => {
        if (res.status) {
          dispatchtoast(res.message)
          setOpenConfirm(false)
          getAllOrders()
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };
  return (
    <>
      <div className="mx-0  mt-10">
        {listSkeleton ? (
          <>
            <Skeleton active />
            <Skeleton className="mt-4" active />
          </>
        ) : orders.length !== 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  place-items-center gap-4">
            {orders?.map((i, index) => (
              <div
                key={index}
                className="bg-white mt-5 shadow-sm shadow-gray-500 w-full  p-4"
              >
                <div className="flex justify-between items-center">
                  <p className="text-sm font-bold"> {i.order_number}</p>
                  <p className="text-gray-600 text-xs font-semibold">
                    {new Date(`${i.order_date_time}`).toLocaleString()}
                  </p>
                </div>
                <p className="text-gray-700 text-xs font-semibold mt-2">
                  Tracking Number:{" "}
                  <span className="font-semibold text-black">
                    {i.order_number}
                  </span>
                </p>
                <div className="flex text-xs justify-between items-center">
                  <p className="text-gray-700 font-semibold mt-2">
                    Quantity:{" "}
                    <span className="font-semibold text-black">
                      {i.total_order_qty}
                    </span>
                  </p>
                  <p className="text-gray-700 font-semibold mt-2">
                    Total Amount:{" "}
                    <span className="font-semibold text-black">
                      {i.order_amount} Rs.
                    </span>
                  </p>
                </div>
                <div className="flex text-sm justify-between mt-4 items-center">
                  <div className="flex text-sm justify-between gap-x-3 items-center">
                    <p
                      onClick={() =>
                        navigate("/orderDetail", { state: i.order_number })
                      }
                      className="font-medium px-3 py-1 border hover:bg-black hover:text-white transition-all border-black cursor-pointer"
                    >
                      Details
                    </p>
                    {status === "Processing" && (
                      <Button
                        style={{ color: "white" }}
                        loading={btnLoading}
                        onClick={() => {
                          setorderid(i.order_number);
                          setOpenConfirm(true);
                        }}
                        className="font-medium px-3 rounded-none py-1 border bg-red-600 border-none hover:border-none hover:text-white transition-all border-black cursor-pointer"
                      >
                        Cancel
                      </Button>
                    )}
                  </div>
                  <p
                    style={{ color: i.color_code }}
                    className=" capitalize font-bold"
                  >
                    {i.order_status}
                  </p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="flex justify-center items-center">
            <LazyLoadImage
              onError={(err) => {
                err.target.src = Images.NoImage;
                err.target.alt = "No Image";
              }}
              src={Images.NoOrder}
              alt="NoOrder"
              className="w-52"
            />
            {/* <p className="font-bold text-lg ">No Order Available!</p> */}
          </div>
        )}
      </div>
      {orders.length !== 0 && (
        <div className="mt-2 mb-8 flex justify-end items-center">
          <CommonPagination
            totalPages={totalPages}
            current={currentPage}
            pageSize={pageSize}
            setCurrent={setCurrent}
            setPageSize={setPageSize}
          />
        </div>
      )}
      {openConfirm && (
        <ConfirmationModal
          fun={handleCancel}
          desc="After cancel the product you are not able to revert it."
          open={openConfirm}
          setOpen={setOpenConfirm}
        />
      )}
    </>
  );
};

export default OrderList;
