import React from "react";

const Footer = () => {
  return (
    <>
      <div className="bg-[#f8f8f8]   font-semibold  px-10 mt-10 md:px-30 lg:px-40">
        <div className="grid  py-16 text-black  grid-cols-2 gap-y-4 sm:grid-cols-3 md:grid-cols-4 place-items-start">
          <div>
            <p className="text-xs text-gray-600 w-1/2">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry...
            </p>
          </div>
          <div className="leading-10 text-gray-600">
            <p className="text-xs mt-2">Home</p>
            <p className="text-xs mt-2">About</p>
            <p className="text-xs mt-2">Recharge & Bill Payment</p>
            <p className="text-xs mt-2">Booking</p>
            <p className="text-xs mt-2">Contact</p>
          </div>
          <div className="text-gray-600">
            <p className="text-xs mt-2">8209086250</p>
            <p className="text-xs mt-2">7007070981</p>
            <p className="text-xs mt-2">deepak@ezulix.com</p>
            <p className="text-xs mt-2">mohit@ezulix.com</p>
            <p className="text-xs mt-2">Plot No. 2, Manyawas</p>
            <p className="text-xs mt-2">Vivek vihar, Jaipur</p>
          </div>
          <div className="text-gray-600">
            <p className="text-lg mb-4">NewsLetter</p>
            <p className="text-xs mt-2">
              Subscribe our newsletter to get our latest update & news
            </p>
          </div>
        </div>
        <div className="w-full h-[1px] bg-black"></div>
        <div className=" text-xs mt-4 pb-4 text-gray-600 grid grid-cols-1 gap-y-3 sm:grid-cols-2 place-items-start md:place-items-center">
          <div className="flex gap-8 justify-between items-center">
            <p>Copyright ©2023</p>
            <p>Ezulix</p>
            <p>All Rights Reserved</p>
          </div>
          <div className="flex gap-8 justify-between items-center">
            <p>Support</p>
            <p>Terms Of Use</p>
            <p>APrivacy Policy</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
