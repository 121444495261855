import React, { useEffect, useState } from "react";
import {
  GetCartList,
  GetPromoCodeList,
  confirmOrder,
  getShippingAddress,
  verifyPromo,
} from "../../ApiServices/Apifun";
import { CiGift, CiMobile1 } from "react-icons/ci";
import { Button, Skeleton, Spin, Tooltip } from "antd";
import { createImageUrl, dispatchtoast, trimString } from "../../Utils";
import { Images } from "../../ImageController/Index";
import { setOpenAddressModal } from "../../Redux/commonSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { IoCheckmarkDoneOutline, IoInformationCircle } from "react-icons/io5";

const Checkout = () => {
  const [address, setAddress] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [totalAmount, setTotalAmount] = useState(0);
  const [cartList, setCartList] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [addressLoading, setAddressLoading] = useState(false);
  const [listSkeleton, setListSkeleton] = useState(false);
  const [promoList, setPromoList] = useState([]);
  const [addressId, setAddressId] = useState(null);
  const isLoggedIn = useSelector((state) => state.user.userDetail);
  const [promoLoading, setPromoLoading] = useState(false);
  const [appliedId, setAppliedId] = useState(null);
  const [discountedPrice, setDiscountedPrice] = useState(0);
  const addressModalState = useSelector(
    (state) => state.common.showAddressModal
  );

  const getAddress = () => {
    setAddressLoading(true);
    getShippingAddress()
      .then((res) => {
        if (res.status) {
          setAddressId(res.data.find((i) => i.is_default === 1).id);
          setAddress(res.data.find((i) => i.is_default === 1));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setAddressLoading(false));
  };
  const GetCartData = () => {
    setListSkeleton(true);
    GetCartList()
      .then((res) => {
        if (res.status) {
          if (res.data.length >= 1) {
            setCartList(res.data.filter((i) => i.is_out_of_stock === 0));
            const total = res.data
              .filter((i) => i.is_out_of_stock !== 1)
              .reduce((a, b) => {
                a = a + b.total_qty_price;
                return a;
              }, 0);
            setTotalAmount(total);
          } else {
            navigate(-1);
          }
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setListSkeleton(false));
  };

  const promoCodes = () => {
    GetPromoCodeList()
      .then((res) => {
        if (res.status) {
          setPromoList(res.data);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (isLoggedIn) {
      GetCartData();
      promoCodes();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!addressModalState && isLoggedIn) {
      getAddress();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressModalState]);
  const handleConfirm = () => {
    setBtnLoading(true);
    const payload = {
      shipping_addr_id: addressId,
      billing_addr_id: addressId,
      promo_code_id: appliedId,
      discounted_amount: discountedPrice,
    };
    confirmOrder(payload)
      .then((res) => {
        if (res.status) {
          navigate("/order-success", { state: { items: cartList,discountedPrice:discountedPrice,orderId:res.order_id } });
        } else {
          GetCartData();
        }
      })
      .catch((err) => {
        console.log(err);
        if (!err.response.data.status) {
          GetCartData();
        }
      })
      .finally(() => setBtnLoading(false));
  };
  const verifyPromoCode = (data) => {
    setPromoLoading(true);
    verifyPromo({
      promo_code_id: data.promo_id,
      cart_price: totalAmount,
    })
      .then((res) => {
        if (res.status) {
          setAppliedId(data.promo_id);
          setDiscountedPrice(res.data.discounted_amount);
          dispatchtoast(res.message);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setPromoLoading(false));
  };
  return (
    <>
      <div className="md:container md:mx-auto mx-4 mt-20 select-none">
        <div className="grid grid-cols-1 md:grid-cols-3 place-items-start gap-x-4">
          <div className="col-span-2 w-full">
            <p className="text-lg font-heading font-semibold  my-2 ">
              Shipping Address:
            </p>
            <Spin spinning={addressLoading}>
              <div className="border mb-4 border-gray-200 rounded-md px-8 py-6">
                <div className="">
                  <div>
                    <p className="font-semibold capitalize">
                      {address?.addr_name}{" "}
                      <span className="border mx-4 border-green-500 text-green-500 rounded-full px-4">
                        {address?.addr_title}
                      </span>
                    </p>
                    <div className="mt-4 text-gray-500 font-semibold capitalize text-[14px]">
                      <p>
                        {address?.addr_address1}, {address?.addr_address2}
                      </p>
                      <p>
                        {address?.addr_city}, {address?.addr_state_id},{" "}
                        {address?.addr_country_id}, {address?.addr_zip}
                      </p>
                    </div>
                    <div className="flex mt-4 justify-between w-full items-center gap-2">
                      <div className="flex justify-start items-center">
                        <CiMobile1 size={20} />
                        <p className="text-gray-500 font-semibold text-[14px]">
                          {address?.addr_phone}
                        </p>
                      </div>
                      <Button
                        style={{ color: "white" }}
                        onClick={() => dispatch(setOpenAddressModal(true))}
                        className="text-white  hover:border-none border-none text-xs flex justify-center items-center bg-black  font-medium  cursor-pointer"
                      >
                        Change Address
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Spin>
            <p className="text-lg text-center font-heading font-semibold  my-2 ">
              Checkout Item List:
            </p>
            <div className=" p-2 shadow-sm rounded-sm shadow-gray-200">
              {listSkeleton ? (
                <>
                  <Skeleton active={true} className="mt-2" />
                  <Skeleton active={true} className="mt-2" />
                </>
              ) : cartList.length !== 0 ? (
                cartList.map((i, index) => {
                  const image = i.productOptionDetail.map((i) => {
                    return {
                      ...i,
                      option_images:
                        typeof i.option_images == "string"
                          ? JSON.parse(i.option_images)
                          : i.option_images,
                    };
                  });
                  return (
                    <>
                      <div className="grid  h-32 relative grid-cols-5 mt-2 font-semibold place-items-center">
                        <div className="col-span-2 w-full">
                          <div className="flex w-full justify-center gap-8 items-center">
                            <LazyLoadImage
                              onError={(err) => {
                                err.target.src = Images.NoImage;
                                err.target.alt = "No Image";
                              }}
                              src={createImageUrl(
                                image.length !== 0
                                  ? image.filter(
                                      (i) => i.option_name === "color"
                                    )[0]?.option_images[0]
                                  : i.defaultImageDetail[0].image
                              )}
                              alt="itemImage"
                              crossOrigin="anonymous"
                              className="w-24 m-auto rounded-sm"
                            />
                            <div className="w-full">
                              <p
                                className="cursor-pointer"
                                // onClick={() =>
                                //   navigate("/productDesc", {
                                //     state: { id: i.product_id },
                                //   })
                                // }
                              >
                                {" "}
                                {i.product_model}
                              </p>
                              <p className="text-xs"> {i.product_name}</p>
                              {i.productOptionDetail.map((i, index) => (
                                <p className="capitalize text-gray-700 text-xs">
                                  {i.option_name}: {i.option_value_name}
                                </p>
                              ))}
                            </div>
                          </div>
                        </div>
                        {/* <div>
                          <div className="flex justify-center items-center gap-x-4 border border-gray-500">
                            <p
                              onClick={() => handleSubtract(i, index)}
                              className="border-r px-3 bg-gray-200 cursor-pointer border-gray-500"
                            >
                              -
                            </p>
                            <p>{i.product_qty}</p>
                            <p
                              onClick={() => handleAdd(i, index)}
                              className="border-l px-3 bg-gray-200 cursor-pointer border-gray-500"
                            >
                              +
                            </p>
                          </div>
                        </div> */}
                        {i.is_shop_user_available !== 1 &&
                        i.is_created_user_available !== 1 ? (
                          <div className="col-span-3">
                            <p className="text-red-500 text-xl font-semibold">
                              Seller not active
                            </p>
                          </div>
                        ) : i.is_all_options_available !== 1 ? (
                          <div className="col-span-3">
                            <p className="text-red-500 text-xl font-semibold">
                              Product Unavailable
                            </p>
                          </div>
                        ) : i.is_out_of_stock === 1 ? (
                          <div className="col-span-3">
                            <p className="text-red-500 text-xl font-semibold">
                              OUT OF STOCK
                            </p>
                          </div>
                        ) : (
                          <div>
                            <p>₹ {i.total_qty_price}</p>
                          </div>
                        )}
                      </div>
                      {index === cartList.length ? (
                        <div className="w-full border border-gray-200 mt-4 h-[1px]"></div>
                      ) : null}
                    </>
                  );
                })
              ) : (
                <div className="flex  justify-center items-center">
                  <div>
                    <LazyLoadImage
                      onError={(err) => {
                        err.target.src = Images.NoImage;
                        err.target.alt = "No Image";
                      }}
                      src={Images.EmptyCart}
                      alt="empty-cart"
                      className="w-36"
                    />
                    <p
                      //   onClick={() => navigate("/")}
                      className="text-center bg-primary p-3 rounded-sm transition-all hover:scale-x-105 cursor-pointer font-semibold text-white "
                    >
                      Explore Products
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="w-full my-10">
            <div>
              <div className="border p-4 bg-[#f8f8f8]">
                <div className="flex justify-center items-center gap-x-2">
                  <p className="font-semibold text-center  text-xl font-heading">
                    Promo Codes
                  </p>
                  <CiGift size={20} />
                </div>
                <div className="flex justify-end items-center ">
                  <p
                    onClick={() => {
                      setAppliedId(null);
                      setDiscountedPrice(0);
                    }}
                    className="font-semibold text-red-700 cursor-pointer"
                  >
                    Remove 
                  </p>
                </div>
                <Spin spinning={promoLoading}>
                  <div className="max-h-[250px] overflow-scroll">
                    {promoList.length !== 0 ? (
                      promoList.map((i, index) => {
                        return (
                          <div
                            key={index}
                            className="flex justify-between items-center"
                          >
                            <div className="mt-2">
                              <div className="flex justify-start items-center gap-x-3">
                                <Tooltip color="lightgray"  title={<p className="text-black text-xs">{i.promo_name}</p>}>
                                <p className="font-bold capitalize">
                                  {trimString(i.promo_name) }
                                </p>
                                </Tooltip>
                                <Tooltip
                                  placement="top"
                                  title={`Minimum eligibal amount is ${
                                    i.min_eligible_amt ?? 0
                                  } to redeem this code.`}
                                >
                                  <p>
                                    <IoInformationCircle />
                                  </p>
                                </Tooltip>
                              </div>
                              <p className="font-semibold text-sm text-gray-400 capitalize">
                                {i.description}
                              </p>
                            </div>
                            {appliedId === i.promo_id ? (
                              <div className="flex justify-start items-center">
                                <Button
                                  className="font-bold text-green-600 underline underline-offset-2"
                                  type="text"
                                >
                                  Applied
                                </Button>
                                <IoCheckmarkDoneOutline color="green" />
                              </div>
                            ) : (
                              <Button
                                onClick={() => verifyPromoCode(i)}
                                className="font-bold text-red-600 underline underline-offset-2"
                                type="text"
                              >
                                Apply
                              </Button>
                            )}
                          </div>
                        );
                      })
                    ) : (
                      <div className="flex mt-2 justify-center items-center">
                        <p>No Promo Code Available</p>
                      </div>
                    )}
                  </div>
                </Spin>
              </div>
            </div>{" "}
            <div className=" my-6">
              <div className=" p-4  bg-[#f8f8f8]">
                <p className="font-semibold text-center  text-3xl font-heading ">
                  Cart Totals
                </p>
                <div className="flex mt-4 justify-between items-center">
                  <p className="text-sm font-bold">Subtotal</p>
                  <p className="font-bold font-mono">₹ {Number(totalAmount)}</p>
                </div>
                <div className="flex mt-4 justify-between items-center">
                  <p className="text-sm font-bold">Discount</p>
                  <p className="font-bold font-mono">
                    {" "}
                    -{Number(discountedPrice)}
                  </p>
                </div>
                <div className="flex mt-4 justify-between items-center">
                  <p className="text-sm font-bold">Total</p>
                  <p className="font-bold font-mono">
                    ₹ {Number(totalAmount) - Number(discountedPrice)}
                  </p>
                </div>

                <div className="flex w-full mt-8 justify-between items-center">
                  <Button
                    loading={btnLoading}
                    onClick={handleConfirm}
                    style={{ color: "white" }}
                    className="w-full bg-black rounded-sm font-bold text-sm text-center h-10 text-white border-none hover:border-none outline-none hover:outline-none"
                  >
                    Place Order
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Checkout;
