import React from "react";
import { Images } from "../ImageController/Index";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FaEye, FaHeart, FaStar } from "react-icons/fa";
import { NavLink } from "react-router-dom";

const CommonCard = ({
  image,
  handleFav,
  data,
  setslug,
  setopen,
  index,
  from = null,
}) => {
  return (
    <>
      <div
        key={index}
        class="relative  flex w-full  flex-col overflow-hidden rounded-md bg-white shadow-sm"
      >
        <div class="relative mx-3 mt-3 flex h-60 overflow-hidden rounded-sm">
          <LazyLoadImage
            onError={(err) => {
              err.target.src = Images.NoImage;
              err.target.alt = "No Image";
            }}
            loading="lazy"
            src={image}
            crossOrigin="anonymous"
            className="w-full object-fill hover:opacity-70 cursor-pointer hover:scale-125  transition-all "
          />
          {/* <span class="absolute top-0 left-0 m-2 rounded-full bg-black px-2 text-center text-sm font-medium text-white">
            0% OFF
          </span> */}
          <div
            onClick={() => handleFav()}
            className={`absolute top-3 right-2 ${
              data.is_favourite || from === "fav" ? "bg-red-600" : "bg-white"
            } shadow-md shadow-gray-500 hover:bg-red-600 hover:scale-105 transition-all cursor-pointer p-2 rounded-full `}
          >
            <FaHeart color={data.is_favourite ? "white" : "lightgray"} />
          </div>
        </div>
        <div class="mt-4 px-5 pb-5">
          <div className="flex justify-between items-center">
            <NavLink to={`/productDesc/${data.slug}`} state={{ id: data.slug }}>
              <h5 class="text-lg capitalize font-semibold tracking-tight text-gray-500">
                {data.product_name}
              </h5>
            </NavLink>
            {data.hasOwnProperty("average_rating") && (
              <h5
                class={`text-sm flex justify-start gap-x-1 items-center capitalize font-semibold tracking-tight ${
                  data.average_rating < 3 ? "bg-red-600" : "bg-green-600"
                } px-3 py-1 rounded-md text-white`}
              >
                {Number(data.average_rating).toFixed(1)} <FaStar size={10} />
              </h5>
            )}
          </div>
          <div class="mt-2 mb-5 flex items-center justify-between">
            <p>
              <span class="text-sm font-semibold  text-primary">
                ₹{data.min_selling_price}
              </span>
            </p>
          </div>
          <div
            onClick={() => {
              setslug(data.slug);
              setopen(true);
            }}
            href="#"
            class="flex items-center gap-x-2 justify-center rounded-sm cursor-pointer bg-primary px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-blue-300"
          >
            <div>
              <FaEye color="white" />
            </div>
            Quick View
          </div>
        </div>
      </div>
    </>
  );
};

export default CommonCard;
