import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProfileTabbar from "./ProfileTab";
import { Button, 
  // DatePicker,
   Form, Input, Modal, Skeleton, Spin } from "antd";
import MyOrderList from "./MyOrders";
import Addresses from "./Addresses";
import ChangePassword from "./ChangePassword";
import { useNavigate } from "react-router-dom";
import { createImageUrl, dispatchtoast } from "../../Utils";
import { MdEdit } from "react-icons/md";
// import moment from "moment";
import dayjs from "dayjs";
import {
  GetUserDetail,
  updateUserProfile,
  updateUserProfileImage,
} from "../../ApiServices/Apifun";
import { setUserAllProfile } from "../../Redux/userSlice";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Images } from "../../ImageController/Index";

const Profile = () => {
  const formref = useRef();
  const fileInputRef = useRef(null);

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const[spinner,setSpinner]=useState(false)
  const[openImageModal,setOpenImageModal]=useState(false)
  const profile = useSelector((state) => state.user.userProfile);
  const isLoggedIn = useSelector((state) => state.user.userDetail);
  const navigate = useNavigate();
  // const memberServices = useSelector((state) => state.B2B.memberServices);
  const [activeTab, setActiveTab] = useState(1);
  useEffect(() => {
    if (!isLoggedIn) {
      navigate(-1);
    }
    if (activeTab === 1) {
      formref.current.setFieldsValue({
        first_name: profile?.user_profile?.user_first_name,
        last_name: profile?.user_profile?.user_last_name,
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);
  const handleUpdate = (val) => {
    let data = { ...val };
    // if(val.db===undefined){
    //   data.dob=(new Date(val.dob).getTime()/1000).toString()
    // }else {
    //   data.dob=(new Date(`"${val.dob.$d}"`).getTime()/1000).toString()
    // }
    updateUserProfile(data).then(res=>{
      if(res.status){
        dispatchtoast(res.message)
        GetUserDetail()
        .then((res) => {
          if (res.status) {
            dispatch(setUserAllProfile(res.data));
          }
        })
        .catch((err) => console.log(err));
      }
    }).catch(err=>console.log(err))
  };
  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = () => {
    setSpinner(true);
    const formdata = new FormData();
    const file = fileInputRef.current.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        console.log(reader.result);
      };
      reader.readAsDataURL(file);
      formdata.append("profile_image", file);
      updateUserProfileImage(formdata)
        .then((response) => {
          if (response.status) {
            GetUserDetail()
              .then((res) => {
                if (res.status) {
                  dispatch(setUserAllProfile(res.data));
                }
              })
              .catch((err) => console.log(err));
            dispatchtoast("Image Updated Successfully");
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => setSpinner(false));
    }
  };
  const className="text-xs rounded-none outline-none hover:outline-none border-t-0 border-l-0 border-r-0  border-b w-full   border-black  h-9 px-2"
  return (
    <>
    <Spin spinning={spinner}>
    <div className="md:container md:mx-auto mx-4 mt-20 select-none">
        <div
          // style={{background:"linear-gradient(220deg, rgb(156 225 210) 0%, rgb(187 237 226) 82%, rgb(204 247 238))"
          // }}
          className=" shadow-sm bg-primary shadow-gray-400 mt-4 rounded-lg"
        >
          <div className="flex relative flex-col m-auto  justify-center gap-x-8 items-center p-4">
            {profile?.user_profile?.image ? (
              <div className="relative rounded-full">
                <LazyLoadImage
                onError={(err)=>{
                  err.target.src=Images.NoImage
                  err.target.alt="No Image"
                }}
                  crossOrigin="anonymous"
                  onClick={()=>setOpenImageModal(true)}
                  className="w-32  aspect-square object-contain rounded-full"
                  src={createImageUrl(
                    profile?.user_profile?.image,
                    "userProfile"
                  )}
                  alt="profilePic"
                />
                <div className="absolute -right-1 cursor-pointer bottom-2">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={() => {
                      handleImageChange();
                      fileInputRef.current.value = "";
                    }}
                    style={{ display: "none" }}
                    ref={fileInputRef}
                  />
                  <MdEdit
                    className="bg-black rounded-full w-7 h-7 p-2"
                    color="white"
                    onClick={handleClick}
                    size={20}
                  />
                </div>
              </div>
            ) : (
              <div className="relative">
                <Skeleton.Avatar active size={150} />
                <div className="absolute -right-1 cursor-pointer bottom-2">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={() => {
                      handleImageChange();
                      fileInputRef.current.value = "";
                    }}
                    style={{ display: "none" }}
                    ref={fileInputRef}
                  />
                  <MdEdit
                    className="bg-primary rounded-full w-7 h-7 p-2"
                    color="white"
                    onClick={handleClick}
                    size={20}
                  />
                </div>
              </div>
            )}
            <p className="text-xl text-white capitalize font-bold text-center">
              {profile?.user_profile?.user_first_name +
                " " +
                profile?.user_profile?.user_last_name}
            </p>
            <p className="text-sm text-white font-medium text-center">
              {profile?.user_profile?.email}
            </p>
          </div>
        </div>
        <div className="mt-2">
          <ProfileTabbar setActiveTab={setActiveTab} activeTab={activeTab} />
        </div>
        {activeTab === 1 ? (
          <div className="personal_detail w-full bg-white shadow-md shadow-gray-500 rounded-md p-4">
            <p className="font-bold text-lg">Personal Info</p>
            <div className="border-t-2 border-primary w-1/2 md:w-1/6"></div>
            <div className="mt-2">
              <Form
                ref={formref}
                form={form}
                initialValues={{
                  dob: profile?.user_profile?.dob
                    ? dayjs(profile?.user_profile?.dob, "YYYY-MM-DD")
                    : null,
                }}
                onFinishFailed={(err) => console.log(err)}
                onFinish={(val) => handleUpdate(val)}
                className="signupForm"
              >
                <div className="grid grid-cols-3 gap-x-3  place-items-center">
                  <Form.Item
                    name={"first_name"}
                    rules={[
                      {
                        required: true,
                        message: "First Name is required",
                      },
                    ]}
                    label="First Name"
                    labelCol={{ span: 24 }}
                    className="w-full"
                  >
                    <Input
                      className={className}
                      placeholder={"Enter first name"}
                    />
                  </Form.Item>
                  <Form.Item
                    name={"last_name"}
                    label="Last Name"
                    labelCol={{ span: 24 }}
                    className="w-full"
                  >
                    <Input
                      className={className}
                      placeholder={"Enter last name"}
                    />
                  </Form.Item>
                  {/* <Form.Item
                    name="dob"
                    label="DOB"
                    labelCol={{ span: 24 }}
                    className="w-full"
                  >
                    <DatePicker placeholder="DOB" className="w-full" />
                  </Form.Item> */}
                </div>
                <div className="flex justify-end items-center">
                  <Button
                    htmlType="submit"
                    style={{ color: "white" }}
                    className="border-none hover:border-none outline-none hover:outline-none bg-primary "
                  >
                    Update
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        ) : activeTab === 2 ? (
          <Addresses />
        ) : activeTab === 4 ? (
          <div className="personal_detail w-full bg-white rounded-md p-4">
            <p className="font-bold text-lg">Your Orders</p>
            <div className="border-t-2 border-primary w-1/2 md:w-1/6"></div>
            <MyOrderList />
          </div>
        ) : activeTab === 5 ? (
          <ChangePassword />
        ) : (
          // <ChangePassword />
          <p>klwjhfklwhlf</p>
        )}
        <Modal
          className="image-Modal shadow-none bg-transparent"
          footer={null}
          closeIcon={null}
          width={300}
          onCancel={()=>setOpenImageModal(false)}
          open={openImageModal}
        >
          <LazyLoadImage onError={(err)=>{
                              err.target.src=Images.NoImage
                              err.target.alt="No Image"
                            }} src={createImageUrl(profile?.user_profile?.image,"userProfile")} crossOrigin="anonymous" className="w-64 h-64 rounded-full" />
        </Modal>
      </div>
    </Spin>
    </>
  );
};

export default Profile;
